@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  padding: 32px 0;

  @include p-size-tablet {
    padding: 80px 0;
  }
}

.price-calculator {
  position: relative;
  padding: 32px;

  background: white;
  box-shadow: 0px 4px 6px rgba(97, 96, 162, 0.01);
  border-radius: 16px;

  z-index: 1;

  @include p-size-mobile {
    padding: 24px;
    margin-right: 0;
  }
}

.background {
  position: relative;

  &:after {
    content: '';

    display: block;

    position: absolute;
    top: -24px;
    right: -24px;

    width: 300px;
    height: 100%;

    background-size: contain;
    background-image: url('/static/pricing-calculator-container/green-intersect.png');
    background-repeat: no-repeat;
    background-position: top right;

    @include p-size-mobile {
      content: none;
    }
  }
}
