@import '@popsure/dirty-swan/dist/grid';

.calculatorContainer,
.inputContainer {
  @include p-size-desktop {
    flex-direction: row !important;
  }
}

.ageInput {
  @include p-size-desktop {
    width: 176px;
  }
}

.oneTimePaymentInput {
  @include p-size-desktop {
    width: 248px;
  }
}

.ageInput label,
.oneTimePaymentInput label {
  font-weight: 700;
}

.monthlyContributionsInput {
  @include p-size-desktop {
    width: 440px;
  }
}

.resultContainer {
  width: 240px;
  margin: 0 auto;

  @include p-size-desktop {
    width: 328px;
  }
}

.warning {
  h3,
  p {
    @include p-size-desktop {
      width: 240px;
      text-align: left;
    }
  }
}

.feesTable {
  @include p-size-tablet {
    padding: 32px 24px;
  }
}

.showTablet {
  display: none;
  @include p-size-tablet {
    display: inline;
  }
}
