@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.priceCalculatorContainer {
  @include p-size-mobile {
    flex-wrap: wrap;
  }
}

.priceCalculatorContentContainer {
  @include p-size-mobile {
    margin-top: 40px;
    flex-direction: column;
  }
}

.premiumContentContainer {
  @include p-size-mobile {
    margin-top: 40px;
  }
}

.animate {
  animation-name: appear-in;
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-timing-function: ease;
}

.hide {
  opacity: 0;
}

@keyframes appear-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
