@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  @include p-size-tablet {
    margin-top: 96px;
  }
}

.background {
  background-color: $ds-grey-100;
}
