@import '@popsure/dirty-swan/dist/grid';

.container {
  @include p-size-mobile {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.pricing-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include p-size-mobile {
    margin: 0;
  }

  &--disabled {
    opacity: 0.3;
  }
}

.cta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.radio-container {
  display: flex;
  flex-direction: column;

  width: fit-content;

  @include p-size-mobile {
    margin-bottom: 40px;
  }
}

.label-container {
  @include p-size-tablet {
    display: flex;
    flex-wrap: wrap;
  }
}

.label {
  min-width: 172px;

  @include p-size-mobile {
    width: 100%;
  }
}
