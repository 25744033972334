@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  transition: all 0.3s ease-in-out;
}

.disabled {
  opacity: 0.6;
}

.sliderContainer {
  height: 18px;
  margin-top: 16px;
  position: relative;
  width: 100%;
}

.sliderBackground {
  position: absolute;
  border-radius: 100px;

  background-color: $ds-purple-50;

  width: 100%;
  height: 18px;

  &::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;

    background-color: $ds-primary-300;
    top: 4px;
    left: 4px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;

    background-color: $ds-primary-300;
    top: 4px;
    right: 4px;
  }
}

.darkBG {
  background-color: $ds-purple-100;
}

.slider {
  position: absolute;
  -webkit-appearance: none;

  width: 100%;
  height: 18px;

  background-color: transparent;

  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  z-index: 10;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    position: relative;

    width: 20px;
    height: 20px;
    border-radius: 50%;

    padding: 8px;

    background-color: $ds-primary-500;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    position: relative;

    z-index: 999;

    width: 2px;
    height: 2px;
    border-radius: 50%;

    padding: 8px;

    background-color: $ds-primary-500;
  }

  &:disabled {
    cursor: default;
  }
}

.recommendedDot {
  position: absolute;
  top: 0;

  &::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;

    background-color: $ds-primary-300;
    top: 4px;
    left: 0;
  }
}

.valueBubbleContainer {
  position: absolute;
  bottom: 32px;
  transform: translateX(-70%);
}

.valueBubble {
  background: #fff;
  bottom: 0;
  filter: drop-shadow(0px 2px 28px rgba(97, 96, 162, 0.12));
  left: 50%;
  padding: 4px 16px;

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 100%;
    left: 50%;
    border: 10px solid transparent;
    border-bottom: none;
    border-top-color: #fff;
    filter: drop-shadow(0px 2px 28px rgba(97, 96, 162, 0.12));
  }
}

.legend {
  display: flex;
  height: 18px;
  justify-content: space-between;
  margin-top: 12px;
  width: 100%;
}

.weRecommend {
  margin-top: 4px;
}

.editButton {
  cursor: pointer;
  border: none;
  background: transparent;

  &:disabled {
    cursor: default;
  }
}

.hideKnob {
  &::-webkit-slider-thumb {
    opacity: 0;
  }
  &::-moz-range-thumb {
    // Animating opacity doesn't work properly with Firefox so we use a fully transparent color instead
    background-color: #00000000;
    border: none;
  }
}
