@import '~@popsure/dirty-swan/dist/grid';
@import '~@popsure/dirty-swan/dist/colors';

.calculator {
  background-color: $ds-grey-100;
}

.private-cta-card {
  text-decoration: none;
}

.general-info {
  margin-top: 48px;
  display: grid;
  row-gap: 16px;

  @include p-size-tablet {
    margin-top: 80px;
    column-gap: 16px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto;
  }

  @include p-size-desktop {
    grid-template-columns: repeat(3, 3fr);
  }
}

.info {
  @include p-size-tablet {
    grid-column-end: span 2;

    &:nth-child(1) {
      grid-column-start: 1;
    }

    &:nth-child(2) {
      grid-column-start: 3;
    }

    &:nth-child(3) {
      grid-column-start: 2;
    }
  }

  @include p-size-desktop {
    &:nth-child(n) {
      grid-column: auto;
    }
  }
}

.table {
  margin: 0 auto;
  max-width: 1318px;
  padding-top: 48px;

  @include p-size-tablet {
    padding-top: 80px;
  }
}
