@import '@popsure/dirty-swan/dist/grid';

.container {
  position: relative;
  max-width: 928px;
}

.containerWithBgColor {
  padding: 32px 0;

  @include p-size-tablet {
    padding: 80px 0;
  }
}

.bgPattern {
  position: absolute;
  width: 228px;
  height: 228px;
  background-image: url('/static/household-insurance/greenPattern.svg');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  top: 78px;
  right: -25px;

  @include p-size-mobile {
    display: none;
  }
}

.calculator {
  position: relative;

  @include p-size-mobile {
    padding: 48px 24px;
  }
}

.calculatorInputs {
  max-width: 356px;

  // Disable Chrome autofill background color
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }
}

.calculatorOutput {
  max-width: 356px;
}

.quoteButton {
  max-width: 264px;
}

.footnote {
  margin-top: 4px;
}
