@import '@popsure/dirty-swan/dist/grid';

@keyframes appear-in {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.priceCalculatorContainer {
  display: flex;
  flex-wrap: wrap;

  @include p-size-mobile {
    display: unset;
  }
}

.priceCalculatorInputContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include p-size-mobile {
    display: unset;
  }
}

.ageInputContainer {
  @include p-size-mobile {
    margin-right: 0;
    width: 100%;
  }
}

.radioLabel {
  width: 120px;
  @include p-size-mobile {
    max-width: unset;
  }
}

.priceCalculatorActionContainer {
  display: flex;
  align-items: center;

  @include p-size-mobile {
    display: unset;
  }
}

.priceCalculatorActionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation-name: appear-in;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: ease;

  @include p-size-mobile {
    margin-top: 48px;
  }
}

.priceCalculatorModalButton {
  background-color: transparent;
  border: none;
  padding: 0;
  font-family: inherit;
}

.errorButton {
  background-color: transparent;
  border: none;
}
