@import '~@popsure/dirty-swan/dist/colors';
@import '~@popsure/dirty-swan/dist/grid';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  width: 100%;

  box-shadow: 0 4px 6px rgba(97, 96, 162, 0.01);
}

.radio-container {
  width: 100%;

  @include p-size-mobile {
    &:nth-child(2) {
      margin-top: 24px;
    }
  }

  @include p-size-tablet {
    max-width: 192px;

    &:first-child {
      margin-right: 24px;
    }
  }

  @include p-size-desktop {
    max-width: 246px;
  }
}

.radio-label {
  width: 100%;
}

.radio-input {
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:focus-visible ~ label {
    box-shadow: 0 0 0 2px $ds-primary-500;
  }
}

.result {
  display: flex;
  flex: 1;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  min-width: 180px;
  max-width: 470px;

  margin: 40px 0;

  h1 {
    font-size: 32px;
    line-height: 42px;
  }

  @include p-size-desktop {
    margin: unset;
  }
}

.below-min-container {
  @include p-size-tablet {
    padding-left: 36px;
  }
}
