@import '@popsure/dirty-swan/dist/grid';

.modal {
  padding: 16px 24px 24px 24px;

  @include p-size-mobile {
    padding-bottom: 80px;
  }
}

.list {
  list-style-type: disc;
  padding-left: 24px;
}
