@import '~@popsure/dirty-swan/dist/colors';
@import '~@popsure/dirty-swan/dist/font-weight';
@import '~@popsure/dirty-swan/dist/grid';

.container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  flex-flow: column;

  animation-name: appear-in;
  animation-timing-function: ease;
  animation-delay: 0.3s;
  animation-duration: 0.6s;
  animation-fill-mode: both;

  @include p-size-tablet {
    flex-flow: row;
  }
}

.contribution-prices-container {
  @include p-size-mobile {
    min-width: 256px;
  }
}

.contribution-prices {
  display: flex;
  align-items: center;
  animation-name: appear-in;
  animation-timing-function: ease;
  animation-delay: 0.3s;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}

@keyframes appear-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.prices-content-container {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-align: left;
}

.color-bar {
  height: 64px;
  width: 16px;
}

.color-bar--health {
  background-color: $ds-purple-500;
}

.color-bar--additional {
  background-color: $ds-purple-500;
  opacity: 0.5;
}

.color-bar--long-term {
  background-color: $ds-purple-500;
  opacity: 0.2;
}

.description-color-bar {
  width: 15px;
  min-height: 24px;
  max-height: fit-content;
}

.prices-details-icon {
  transition: transform 0.3s;
}

.prices-details-icon-rotate {
  transform: rotate(180deg);
}

.graph-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-linecap: round;

  position: absolute;
}

.health-insurance-circle {
  z-index: 1;
  stroke: $ds-primary-500;
  fill: transparent;
}

.additional-circle {
  z-index: 1;
  stroke: $ds-primary-500;
  opacity: 0.5;
  fill: transparent;
}

.long-term-care-circle {
  z-index: 1;
  stroke: $ds-primary-500;
  opacity: 0.2;
  fill: transparent;
}

.inner-circle {
  z-index: 0;
  stroke: $ds-grey-200;
  fill: transparent;
}

.graph-contribution-amount {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes appear-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
